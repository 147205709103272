import { useState, useContext, useEffect } from "react";
import { Button } from 'react-bootstrap';
import $api from "../../http/api.config";
import 'bootstrap/dist/css/bootstrap.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { observer } from 'mobx-react-lite';
import {paymentOptions, statusOptions, approvalStatusOptions} from "../../util/choices/ProjectOptions";
import { useStore } from "../../store/hooks";

const FilterModal = ({
	show, 
	onHide, 
	elements,
	setElements,
	setNextPageUrl
}) => {
	const baseStore = useStore("baseStore");
	const authStore = useStore("authStore");
	const [filterData, setFilterData] = useState({});

	useEffect(() => {
		localStorage.getItem('user') && (filterData['user'] = localStorage.getItem('user'));
		localStorage.getItem('status') && (filterData['status'] = localStorage.getItem('status'));
		localStorage.getItem('payment') && (filterData['payment'] = localStorage.getItem('payment'));
		localStorage.getItem('customer') && (filterData['customer'] = localStorage.getItem('customer'));
		localStorage.getItem('user') && (filterData['user'] = localStorage.getItem('user'));
		localStorage.getItem('page_size') && (filterData['page_size'] = localStorage.getItem('page_size'));
		filter();
	}, []);

	useEffect(() => {
		filter();
	}, [filterData]);

	const filter = () => {
		$api.get('/api/v1/projects/', {
			params: filterData
		}).then((response) => {
            console.log(response);
            setElements([...response.data.results]);
			setNextPageUrl(response.data.next);
        }).catch(err => {
            console.log(err);
        });
	}

	const propChanged = (e, filterName) => {
		let value = "";
		const fieldType = e.target.type;

		console.log(e.target.values);
		console.log(fieldType);

		if(fieldType === 'checkbox') {
			value = e.target.checked;
		} else if(fieldType === 'select-one') {
			value = e.target.value;
		} else if(fieldType === 'select-multiple') {
			let values = [];

			for (const option of e.target.options) {
				if (option.selected) {
				  values.push(option.value);
				}
			}
			value = values.join(',');
		}

		console.log(value);

		if(value) {
			localStorage.setItem(filterName, value);
			filterData[filterName] = value;
		} else {
			localStorage.removeItem(filterName);
			delete filterData[filterName];
		}
		
		setFilterData({...filterData});
	}

	const customCheckboxPropChanged = (e, filterName) => {
		const value = e.target.value;
		const checked = e.target.checked;
		
		if(checked) {
			localStorage.setItem(filterName, value);
			filterData[filterName] = value;
		} else {
			localStorage.removeItem(filterName);
			delete filterData[filterName];
		}
		filter();
	}

	const dropFilterData = () => {
		for(let key in filterData) {
			if(filterData.hasOwnProperty(key)) {
				delete filterData[key];
				localStorage.removeItem(key);
			}
		}
		setFilterData({...filterData});
	}
	
	return (
		<Offcanvas show={show} onHide={onHide} placement="start">
			<Offcanvas.Header closeButton>
			<Offcanvas.Title>Фильтр</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
			<div className="row">
					<div className="col mb-2">
						<div className="form-switch">
							<input 	onChange={e => customCheckboxPropChanged(e, 'page_size')}
									className="form-check-input me-2" 
									type="checkbox" 
									name="all_projects" 
									id="all_projects"
									checked={filterData.page_size}
									value="5000" />
							<label className="form-label" htmlFor="all_projects">Все проекты</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col mb-2">
						<div className="form-switch">
							<input 	onChange={e => customCheckboxPropChanged(e, 'user')}
									className="form-check-input me-2" 
									type="checkbox" 
									name="user" 
									id="user"
									checked={filterData.user == authStore.user.id}
									value={authStore.user.id} />
							<label className="form-label" htmlFor="user">Только мои</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col mb-2">
						<label className="form-label" htmlFor="number">Статус</label>
						<select onChange={e => propChanged(e, 'status__in')} 
								className="form-select" 
								name="status"
								multiple>
							<option value=''>Любой</option>
							{ statusOptions && statusOptions.map((item, key) => {
								return <option key={key} value={item.value}>{item.name}</option>
							}) }
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col mb-2">
						<label className="form-label" htmlFor="number">Оплата</label>
						<select onChange={e => propChanged(e, 'payment')}
								name="payment"
								className="form-control"
								value={filterData['payment'] || ''}>
							<option value=''>Любая</option>
							{ paymentOptions && paymentOptions.map((item, key) => {
								return <option key={key} value={item.value}>{item.name}</option>
							}) }
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col mb-3">
						<label className="form-label" htmlFor="number">Заказчик</label>
						<select onChange={e => propChanged(e, 'customer')}
								className="form-select" 
								name="customer"
								value={filterData['customer'] || ''}>
							<option value=''>Любой</option>
							{ baseStore.customers && baseStore.customers.map((item, key) => {
								return <option key={key} value={item.id}>{item.name}</option>
							}) }
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col mb-3">
						<label className="form-label" htmlFor="number">Сотрудник</label>
						<select onChange={e => propChanged(e, 'user')}
								className="form-select" 
								name="user"
								value={filterData['user'] || ''}>
							<option value=''>Любой</option>
							{ baseStore.users && baseStore.users.map((item, key) => {
								return <option key={key} value={item.id}>{item.first_name}</option>
							}) }
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<Button onClick={dropFilterData} variant="outline-secondary">
							Сбросить
						</Button>
					</div>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default observer(FilterModal);