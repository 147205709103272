import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Main from './components/pages/Main';
import Auth from './components/pages/Auth';

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from './store/hooks';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Homepage from './components/pages/Homepage/Homepage';
import AuthStore from './store/AuthStore';

const App = observer(() => {
	const authStore = useStore('authStore');
	const baseStore = useStore('baseStore');
	const [isAuth, setIsAuth] = useState(authStore.isAuth);

	useEffect(() => {
		document.title = "GalamTec";
		if(localStorage.getItem('accessToken') || localStorage.getItem('refreshToken')) {
			authStore.checkAuth();
		}
		baseStore.intitialize();
	});
	
	return (
		<div className="App">
			<Routes>
				<Route path="/login" element={<Auth />} />
				<Route path="/*" element={<PrivateRoute />}>
					<Route path="*" element={<Main />} />
				</Route>
			</Routes>
		</div>
	);
});

export default App;