import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from "./../../store/hooks";

import Homepage from './Homepage/Homepage';
import '../../static/scss/main.scss';

import Header from '../general/Header';
import Footer from '../general/Footer';
import ProjectDetail from './Detail/ProjectDetail';
import Customers from './Customers';
import CustomerDetail from './Detail/CustomerDetail';
import PrivateRoute from './../../components/PrivateRoute/PrivateRoute';


const Main = observer(() => {
    return (
        <>
            <div className='main_wrapper'>
                <Header />

                <Routes>
                    <Route path="/" element={
                        <Homepage />} />
                    <Route path="/projects/" element={
                        <Homepage />} />
                    <Route path="/projects/:id/" element={
                        <ProjectDetail />} />
                    <Route path="/customers/" element={
                        <Customers /> }/>
                    <Route path="/customers/:id/" element={
                        <CustomerDetail /> }/>
                </Routes>
            </div>

            <Footer />
        </>
    )
});

export default Main;