import React from "react";
import BaseStore from "./BaseStore";
import AuthStore from "./AuthStore";

export const stores = Object.freeze({
  baseStore: new BaseStore(),
  authStore: new AuthStore()
});

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;