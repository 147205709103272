import { useAccordionButton } from 'react-bootstrap/AccordionButton';

function MainPageAccordionCustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    {}
  );

  return (
    <div
      className=''
      type="button"
      style={{  }}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

export default MainPageAccordionCustomToggle;