import Container from 'react-bootstrap/esm/Container';
import '../../static/scss/Auth.scss';
import logo from '../../static/logo0.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { API_URL } from "./../../http/api.config";
import AuthService from '../../services/AuthService';
import { useStore } from '../../store/hooks';



const Auth = observer(() => {
    const authStore = useStore('authStore');
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('Неправильное имя пользователя или пароль');
    const [error, setError] = useState(false);
    const token = localStorage.getItem('token');

    // useEffect(() => {
    //     if(!token) {
    //         navigate('/');
    //     }
    // }, []);
    
    const authtorize = async (e) => {
        e.preventDefault();
        
        const response = await authStore.login(username, password);

        navigate("/");

        // const response = await axios.post(`${API_URL}/auth/jwt/create`, {
        //     'username': `${username}`,
        //     'password': `${password}`
        // });
        // localStorage.setItem('accessToken', response.data.access);
        // localStorage.setItem('refreshToken', response.data.refresh);
        // console.log(response);
    }

    return (
        <>
            <Container className='auth-container'>
                <main className="form-signin w-100 m-auto">
                    <form>
                        <img className="mb-4" src={logo} alt="" />
                        <h1 className="h3 mb-3 fw-normal">Войти в систему</h1>

                        <div className="form-floating">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Логин" onChange={e => setUsername(e.target.value)} value={username}/>
                            <label htmlFor="floatingInput">Логин</label>
                        </div>
                        <div className="form-floating">
                            <input type="password" className="form-control" id="floatingPassword" placeholder="Пароль" onChange={e => setPassword(e.target.value)} value={password}/>
                            <label htmlFor="floatingPassword">Пароль</label>
                        </div>
                        <div className={"alert alert-danger" + (error ? '' : ' d-none') } role="alert">
                            {errorMessage}
                        </div>
                        <button onClick={authtorize} className="w-100 btn btn-lg btn-primary" type="submit">Войти</button>
                        <p className="mt-5 mb-3 text-muted">© {new Date().getFullYear()}</p>
                    </form>
                </main>
            </Container>
        </>
    )
});

export default Auth;