import { useState, useContext } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { Context } from '../contexts/HomeContext';
import { useEffect } from "react";
import { IMaskInput } from "react-imask";

const AddCustomerModal = ({BASE_URL, show, onHide}) => {
    const [resultMessage, setResultMessage] = useState();
    const {setCustomersFun} = useContext(Context);
    let currentDate = new Date().toJSON().slice(0, 10);
    const errorText = 'Поле не должно быть пустым';

    const PhoneMask = "+{0} (000) 000-00-00";
    const EmailMask = /^\S*@?\S*$/;
    

    // New project required fields
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [number, setNumber] = useState('');
    const [numberError, setNumberError] = useState(false);
    const [customer, setCustomer] = useState('');
    const [customerError, setCustomerError] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    
    const addNewCustomer = (e) => {
        e.preventDefault();

        let form = document.querySelector('form');
        let formData = new FormData(form);

        const url = BASE_URL + '/api/customers/add/';
        
        if(checkRequiredFields()) {
            return;
        } else {
            setShowLoader(true);
            axios.post(url, formData, {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                console.log(response);

                setCustomersFun(response.data.customers);                
                setResultMessage(dataChangedAlerts(response));


            }).catch(err => {
                console.log(err);
                setResultMessage(dataChangedAlerts(err.response));
            }).finally(() => {
                setShowLoader(false);
            });
        }
    }

    const dataChangedAlerts = (response) => {
        let text = "Данные успешно добавлены";
        let className = 'alert-success';
        if(response.status !==200) {
            text = response.data.detail ? response.data.detail : 'Не удалось изменить данные. Попробуйте позже.';
            className = 'alert-danger';
        }

        setTimeout(() => {setResultMessage(null)}, 20000);

        // scroll to alert
        let objDiv = document.querySelector(".modal-body");
        objDiv.scrollTop = objDiv.scrollHeight + 500;

        return <>
                <div className={'alert ' + className} role="alert">
                    {text}
                </div>
            </>
    }

    const checkRequiredFields = () => {
        name.trim() ==="" ? setNameError(true) : setNameError(false);
        // number.trim() ==="" ? setNumberError(true) : setNumberError(false);
        // email.trim() ==="" ? setEmailError(true) : setEmailError(false);
        console.log(name.trim());
        return name.trim() ==="" ? true : false;
    }


    return (
        <Modal  show={show} 
                onHide={onHide} 
                scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>Добавить заказчика</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form method='POST'>
                    <div className="form-group text-left">
                        <label htmlFor="name">Наименование</label>
                        <input onInput={(e) => {setName(e.target.value)}} type="text" className="form-control" id="name" name='name' placeholder="Наименование" />
                    </div>
                    <div className="group_of_numbers">
                        <div className="form-group text-left">
                            <label htmlFor="phoneNumber">Номер телефона</label>
                            <IMaskInput
                                mask={PhoneMask}
                                value=""
                                id="phoneNumber"
                                name="phoneNumber"
                                // onAccept={(value, mask) => console.log(value, mask)}
                                placeholder="Номер телефона"
                                className="form-control"
                            />
                            {/* <input type="text" className="form-control" id="phoneNumber" name='phoneNumber' placeholder="Номер телефона" /> */}
                        </div>
                    </div>
                    <div className="group_of_emails">
                        <div className="form-group text-left">
                            <label htmlFor="email">Email</label>
                            <IMaskInput
                                mask={EmailMask}
                                value=""
                                id="email"
                                name="email"
                                onAccept={(value, mask) => console.log(value, mask)}
                                placeholder="Электронная почта"
                                className="form-control"
                            />
                        </div>
                    </div>
                </form>
                {resultMessage}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                { showLoader ?
                    <div className="filter_loader_box">
                        <div id="loader-4">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    :
                    <div></div>
                }
                <div className="buttons">
                    <Button variant="secondary" onClick={onHide}>
                        Отмена
                    </Button>
                    <Button variant="primary" className='ml-2' onClick={addNewCustomer}>
                        Сохранить изменения
                    </Button>
                </div>
            </Modal.Footer>
        </Modal> 
    )
}

export default AddCustomerModal;