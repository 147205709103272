import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { useEffect } from "react";
import $api from "../../http/api.config";
import useAlert from "../../util/Alert";
import { useStore } from "../../store/hooks";
import { observable } from "mobx";
import { observer } from "mobx-react-lite";

const AddProjectModal = ({show, onHide, appendNewProject}) => {
	const baseStore = useStore("baseStore");
	const [resultMessage, setResultMessage] = useState();
	let currentDate = new Date().toJSON().slice(0, 10);
	const errorText = 'Поле не должно быть пустым';
	const [numberError, setNumberError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [customers, setCustomers] = useState([]);
	const [users, setUsers] = useState([]);
	const [alert, setAlert] = useAlert();

	useEffect(() => {
		// получаем список заказчиков
		$api.get('/api/v1/customers/').then((response) => {
            console.log(response);
            setCustomers(response.data);
        }).catch(err => {
            console.log(err);
        });

		// получаем список работников
		$api.get('/api/v1/users/').then((response) => {
            console.log(response);
            setUsers(response.data);
        }).catch(err => {
            console.log(err);
        });
	}, []);
	
	const addNewProject = (e) => {
		e.preventDefault();
		const formData = new FormData(document.getElementById('addProjectFrom'));
		checkRequiredFields(formData);
		if(numberError || nameError) {
			return;
		} else {
			setShowLoader(true);
			$api.post('/api/v1/projects/', Object.fromEntries(formData)).then(response => {
			    console.log(response);
			    appendNewProject(response.data);
			}).catch(err => {
				console.log(err);
			    setAlert(err.response);
			}).finally(() => {
			    setShowLoader(false);
			});
		}
	}

	const checkRequiredFields = (formData) => {
		setNumberError(formData.get('project_number').trim() ? false : true);
		setNumberError(formData.get('name').trim() ? false : true);
	}

	return (
		<Modal  show={show}
				onHide={onHide}
				scrollable={true}>
			<Modal.Header closeButton>
				<Modal.Title>Добавить проект</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form method='POST' id="addProjectFrom">
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="project_number">№ проекта</label>
						</div>
						<div className="col col-12">
							<input type="number" className={numberError ? 'form-control is-invalid' : 'form-control'} id="project_number" name='project_number' />
							{ numberError && <div className="invalid-feedback">
								{errorText}
							</div> }
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="customer_project_number">№ проекта заказчика</label>
						</div>
						<div className="col col-12">
							<input type="text" className="form-control" id="customer_project_number" name='customer_project_number' />
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="name">Наименование</label>
						</div>
						<div className="col col-12">
							<input type="text" className={nameError ? 'form-control is-invalid' : 'form-control'} id="name" name='name' />
							{ nameError && <div className="invalid-feedback">
								{errorText}
							</div>}
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="customer">Заказчик</label>
						</div>
						<div className="col col-12">
							<select className="form-control" id="customer" name='customer'>
								{ baseStore.customers && baseStore.customers.map((item, key) => {
									return <option key={key} value={item.id}>{item.name}</option>
								}) }
							</select>
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="user">Конструктор</label>
						</div>
						<div className="col col-12">
							<select className="form-control" id="user" name='user'>
								{ baseStore.users && baseStore.users.map((item, key) => {
									return <option key={key} value={item.id}>{item.first_name}</option>
								}) }
							</select>
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="start_date">Дата начала разработки</label>
						</div>
						<div className="col col-12">
							<input type="date" className="form-control" id="start_date" name='start_date' defaultValue={currentDate} />
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="end_date">Дата окончания разработки</label>
						</div>
						<div className="col col-12">
							<input type="date" className="form-control" id="end_date" name='end_date' defaultValue={currentDate} />
						</div>
					</div>
					{/* <div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="payment">Оплата</label>
						</div>
						<div className="col col-12">
							<select className="form-control" id="payment" name='payment'>
								<option>Не оплачено</option>
								<option>Частично оплачен</option>
								<option>Оплачено</option>
							</select>
						</div>
					</div> */}
				</form>
				{ alert && <div className="row">
                    <div className="col">
                        { alert }
                    </div>
                </div> }
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				{ showLoader ?
					<div className="filter_loader_box">
						<div id="loader-4">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					:
					<div></div>
				}
				<div className="buttons">
					<Button variant="secondary" onClick={onHide}>
						Отмена
					</Button>
					<Button variant="primary" className='ms-2' onClick={addNewProject}>
						Добавить
					</Button>
				</div>
			</Modal.Footer>
		</Modal> 
	)
}

export default observer(AddProjectModal);