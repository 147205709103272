import { useState } from "react";
import { Link } from "react-router-dom";
import loopIcon from "../../static/images/loop.svg";
import { observer } from "mobx-react-lite";
import $api from "../../http/api.config";

const SearchBlock = () => {
	const [findBtnValue, setFindBtnValue] = useState('');
	const [foundedProjects, setFoundedProjects] = useState(null);
	const findProjects = (e) => {
		setFindBtnValue(e.target.value);
		if(e.target.value.trim() !=='') {
			$api.get('/api/v1/projects/', {
				params: {
					'search': e.target.value,
					'page_size': 10
				}
			}).then(response => {
				console.log(response);
				if(response.data.results.length !== 0) {
					setFoundedProjects(response.data.results);
				} else {
					setFoundedProjects(null);
				}
			}).catch(err => {
				console.log(err);
			});
		} else {
			setFoundedProjects(null);
		}
	}

	return (
		<div className="search-wrapper input-group">
			<input value={findBtnValue} onChange={findProjects} type="text" className="form-control" placeholder="Поиск" aria-label="Username" aria-describedby="basic-addon1" />
			{ foundedProjects && 
				<ul className="dropdown-menu show">
					{Object.keys(foundedProjects).map(function(key, index) {
						return  <Link key={index} to={'/projects/' + foundedProjects[key].id + '/'} className='dropdown-item'>
									# {foundedProjects[key].project_number}, {foundedProjects[key].name}
								</Link>
					})}
				</ul> }
		</div>
	);
}

export default observer(SearchBlock);