import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import classes from "./Homepage.module.scss";
import { Button } from "react-bootstrap";
import Loader from "../loader/Loader";
import axios from "axios";
import AddCustomerModal from "../modals/AddCustomerModal";
import { Context } from "../contexts/HomeContext";

const Customers = ({BASE_URL}) => {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState(null);
    const [customersByDefautl, setCustomersByDefault] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [isLoaded, setIsLoaded] =  useState(false);
    const [user, setUser] = useState();
    const [page, setPage] = useState(2);
    const [pages, setPages] = useState(true);

    useEffect(() => {
        const url = BASE_URL + '/api/customers/';
        axios.get(url, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        }).then(response => {
            // console.log(response);

            if(response.data.user && !response.data.user.admin)
                navigate('/crm/');

            setCustomers(response.data.customers);
            setCustomersByDefault(response.data.customers);
            setIsLoaded(true);

            setUser(response.data.user);

            if(!response.data.next_page) {
                setPages(false);
            }
        }).catch(err => {
            setCustomers(null);

            if(err.response.status ===401) {
                navigate('/login');
            }
            if(err.response.statusText ==='Unauthorized') {
                navigate('/login');
            }
            // console.log(err);
        });
    }, []);

    const loadMore = () => {
        let url = BASE_URL + '/api/customers/loadMore/' + page + "/";
        axios.get(url, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        }).then(response => {
            // console.log(response);

            setCustomers([...customers, ...response.data.customers]);

            if(response.data.next_page) {
                setPage(page + 1);
            } else {
                setPages(false);
            }
        }).catch(err => {
            // setProjects(null);
            setPages(false);
            // console.log(err);
            console.log('error');
        });
    }

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const setCustomersFun = (customers) => {
        setCustomers(customers);
    }

    return (
        <Context.Provider value={
            {setCustomersFun}
        }>
            <div className="cnt_wrapper container-fluid">
                <div className="group_block">
                    <div className="title_block">
                        <h3 className="title">
                            Заказчики
                        </h3>
                        { (isLoaded && (user.admin || user.manager || user.chief_constructor)) && <div onClick={handleShowAddModal} className="add_new_project">
                            <svg width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 368C269.3 368 280 357.3 280 344V280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H280V168C280 154.7 269.3 144 256 144C242.7 144 232 154.7 232 168V232H168C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H232V344C232 357.3 242.7 368 256 368z"/>
                            </svg>
                        </div> }
                    </div>
                    <br />
                    
                    { isLoaded ?
                        <>
                            <div className="table">
                                <div className="row row-header">
                                    <div className="col col-2 col-md-1">#</div>
                                    <div className="col col-10 col-md-11">Заказчик</div>
                                </div>
                                {Object.keys(customers).map(function(key, index) {
                                    let statusClass = '';
                                    if(customers[key].status ==='Завершено') statusClass = 'bg-success';
                                    else if(customers[key].status ==='В работе') statusClass = 'bg-warning';
                                    else statusClass = 'bg-danger';

                                    return  <Link key={index} to={customers[key].id + '/'} className="row">
                                                <div className="col col-2 col-md-1" data-label="№">{customers[key].id}</div>
                                                <div className="col col-10 col-md-11" data-label="Наименование:">{customers[key].name}</div>
                                            </Link>
                                })}
                            </div>
                            { pages && <Button onClick={loadMore} variant="primary" show={false}>Загрузить еще</Button> }
                        </>
                        : <Loader/> }
                </div>
            </div>

            { isLoaded && 
                <AddCustomerModal BASE_URL={BASE_URL} 
                                  show={showAddModal} 
                                  onHide={handleCloseAddModal}
                                  customers={customers} />
            }
        </Context.Provider>
    );
}

export default Customers;