import './Loader.scss';
import logo from '../../static/logo.png';

const Loader = () => {
    return (
        <div className='loader-wrapper h-100'>
            <div className='loader loader1'>
                <div>
                    <div>
                    <div>
                        <div>
                        <div>
                            <div></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader;