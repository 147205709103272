import { makeAutoObservable } from "mobx";
import { IUser } from "../models/IUser";
import $api from "../http/api.config";

export default class BaseStore {
	totalProjectsCountOnPage: number = 0;
	customers: Array<string> = [];
	users: Array<string> = [];

	constructor() {
		makeAutoObservable(this);
	}

	intitialize() {
		$api.get<Array<string>>('/api/v1/customers/').then((response) => {
			console.log(response);
			this.setCustomers(response.data);
		}).catch(err => {
			console.log(err);
		});

		$api.get<Array<string>>('/api/v1/users/?is_active=true').then((response) => {
			console.log(response);
			this.setUsers(response.data);
		}).catch(err => {
			console.log(err);
		});
	}

	setCustomers(customers: Array<string>) {
		this.customers = customers;
	}

	setUsers(users: Array<string>) {
		this.users = users;
	}

	setTotalProjectsCountOnPage(count: number) {
		this.totalProjectsCountOnPage = count;
	}
}