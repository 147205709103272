import { makeAutoObservable } from "mobx";
import AuthService from "./../services/AuthService";
import UserService from "./../services/UserService";
import axios from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import { useNavigate } from "react-router-dom";
import { IUser } from "../models/IUser";

export default class AuthStore {
	isAuth = false;
	isAuthInProgress = false;
	user_id: number | null;
	username: string;
	isStaff: boolean;
	user = {} as IUser;

	constructor() {
		makeAutoObservable(this);
		this.username = "";
		this.isStaff = true;
		this.user_id = null;
	}

	setUserId(user_id: number | null) {
		this.user_id = user_id;
	}

	setUser(user: IUser) {
		this.user = user;
	}

	setUsername(user: IUser) {
		if(user.first_name) {
            this.username = user.first_name;
        } else {
            this.username = user.username;
        }
	}

	setIsAuth(value: boolean) {
		this.isAuth = value;
	}

	setIsAuthInProgress(value: boolean) {
		this.isAuthInProgress = value;
	}

	async login(email: string, password: string) {
		this.setIsAuthInProgress(true);
		try {
			const resp = await AuthService.login(email, password);
			localStorage.setItem("accessToken", resp.data.access);
			localStorage.setItem("refreshToken", resp.data.refresh);
			this.setIsAuth(true);
		} catch (err) {
			console.log(err.response?.data?.message);
		} finally {
			this.setIsAuthInProgress(false);
		}
	}

	async checkAuth() {
		this.setIsAuthInProgress(true);
		try {
			const resp = await AuthService.refresh();
			localStorage.setItem("accessToken", resp.data.access);
			localStorage.setItem("refreshToken", resp.data.refresh);
			this.getUserData();
			this.setIsAuth(true);
		} catch (err) {
			console.log(err);
			// this.logout();
		} finally {
			this.setIsAuthInProgress(false);
		}
		return this.isAuth;
	}

	async logout() {
		this.setIsAuthInProgress(true);
		try {
			const resp = await AuthService.refresh();
			this.setIsAuth(false);
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			document.location.href = '/login';
		} catch (err) {
			console.log("logout error");
		} finally {
			this.setIsAuthInProgress(false);
		}
	}

	async getUserData() {
		try {
			const resp = await UserService.getCurrentUser();
			// console.log(resp.data);
			this.setUser(resp.data);
			this.setUsername(this.user);
		} catch (err) {
			console.log(err);
		}
	}
}