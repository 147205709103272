import { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/hooks";
import $api from "../../http/api.config";
import useAlert from "../../util/Alert";

const AddFileModal = observer(({ projectId, show, onHide }) => {
    const baseStore = useStore("baseStore");
    const [files, setFiles] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);
    const [alert, setAlert] = useAlert();
    
    const addFiles = (e) => {
        e.preventDefault();
        setShowLoader(true);

        const url = '/files/upload';
        let formData = new FormData();

        for(let i = 0; i < files.length; i++) {
            formData.append('files', files[i])
        }

        formData.append("projectId", projectId);
        
        $api.post(url, formData).then(response => {
            // console.log(response);
            setAlert(response.status);
            setFiles(...[]);
        }).catch(err => {
            // console.log(err);
            setAlert(err.response.status);
        }).finally(() => {
            setShowLoader(false);
        });
    }

    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function(e) {
        // console.log(e);
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFiles([...Array.from(e.dataTransfer.files)]);
        }
    };
    
    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setFiles([...Array.from(e.target.files)]);
            // console.log(files);
        }
    };
    
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handleCloseButton = () => {
        setFiles([]);
        onHide();
    }

    return (
        <Modal  className="file-upload-modal"
                show={show}
                onHide={handleCloseButton}
                scrollable={true}
                centered>
            <Modal.Header closeButton>
                <Modal.Title>Загрузить файлы</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                    <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                    <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                        <div>
                            <p>Перетащите ваш файл сюда или</p>
                            <button onClick={onButtonClick} className="upload-button">Загрузите файл</button>
                        </div> 
                    </label>
                    { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                </form>
                <div className="files">
                    <ul>
                        { (files && files.length > 0) && files.map((el, index) => {
                            return <li key={index}>
                                {el.name}
                                <span className="file-size ms-2">
                                    {el.size ? Math.round(el.size / 1000) + ' KB' : '-'}
                                </span>
                            </li>
                        })}
                    </ul>
                </div>

                { alert && <div className="row">
                    <div className="col">
                        { alert }
                    </div>
                </div> }

                { showLoader &&
                    <div className="fond">
                        <div className="contener_general">
                            <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
                            <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
                            <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
                            <div className="contener_mixte"><div className="ballcolor ball_4">&nbsp;</div></div>
                        </div>
                    </div> 
                }
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <div className="buttons">
                    <Button variant="secondary" onClick={handleCloseButton}>
                        Отмена
                    </Button>
                    <Button onClick={(e) => addFiles(e)} variant="primary" className='ms-2'>
                        Загрузить
                    </Button>
                </div>
            </Modal.Footer>
        </Modal> 
    )
});

export default AddFileModal;