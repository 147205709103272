import { Navigate, Outlet, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "./../../store/hooks";
import { useEffect } from "react";

const PrivateRoute = (props) => {
    const authStore = useStore('authStore');
    const TOKEN = localStorage.getItem('accessToken') || localStorage.getItem('refreshToken');
    if (TOKEN || authStore.isAuth) {
        return <Outlet/>
    } else {
        return <Navigate to="/login" />;
    }
};
  
export default observer(PrivateRoute);