import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../loader/Loader";
import { Button, Modal } from "react-bootstrap";
import AddProjectModal from "../../modals/AddProjectModal";
import FilterModal from "../../modals/FilterModal";
import Accordion from 'react-bootstrap/Accordion';
import MainPageAccordionCustonToggle from '../../parts/MainPageAccordionCustomToggle';
import SearchBlock from "../../widgets/SearchBlock";
import $api from '../../../http/api.config';
import { useStore } from '../../../store/hooks';
import CustomProgressBar from "../../parts/CustomProgressBar";
import ProjectsResponse from '../../../models/response/ProjectsResponse';
import { 
	PlusSquareFill,
	Calendar2Event,
	List,
	ChevronCompactDown,
	PersonGear,
	SortDown,
	Funnel
} from 'react-bootstrap-icons';
import { statusOptions } from "../../../util/choices/ProjectOptions";

const Homepage = () => {
	const baseStore = useStore('baseStore');
	const [projects, setProjects] = useState([]);
	const [isLoaded, setIsLoaded] =  useState(false);    
	const [nextPageUrl, setNextPageUrl] = useState(null); 
	const [page, setPage] = useState(1);
	const [showAddProjectModal, setShowAddProjectModal] = useState(false);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [showLoadMoreLoader, setShowLoadMoreLoader] = useState(false);

	useEffect(() => {
		// Получаем проекты
		$api.get('/api/v1/projects/?ordering=-project_number').then((response) => {
			console.log(response);
			setProjects([...response.data.results]);
			setNextPageUrl(response.data.next);
			baseStore.setTotalProjectsCountOnPage(projects.length);
			setIsLoaded(true);
		}).catch(err => {
			console.log(err);
		});
	}, []);

	// Add new project modal
	const handleShowAddModal = () => setShowAddProjectModal(true);
	const handleCloseAddModal = () => setShowAddProjectModal(false);

	// Add filter modal
	const handleShowFilterModal = () => setShowFilterModal(true);
	const handleCloseFilterModal = () => setShowFilterModal(false);


	const loadMore = () => {
		setShowLoadMoreLoader(true);
		if(nextPageUrl) {
			const url = new URL(nextPageUrl);
			console.log(url);
			$api.get(url.pathname + url.search).then((response) => {
				console.log(response);
				setProjects([...projects, ...response.data.results]);
				setNextPageUrl(response.data.next);
				baseStore.setTotalProjectsCountOnPage(projects.length);

				const pageArg = parseInt((url.search).match(/\d+/g));
				setPage(pageArg);
			}).catch(err => {
				console.log(err);
			});
		}
		setShowLoadMoreLoader(false);
	}

	const appendNewProject = (project) => {
		setProjects([project, ...projects]);
	}

	const sort = (e) => {
		let value = e.target.value;
		let sorted;

		switch(value) {
			case "default":
				sorted = [...projects].sort((a, b) => (a.id > b.id) ? -1 : 1);
				break;
			case "number_asc":
				sorted = [...projects].sort((a, b) => (a.project_number > b.project_number) ? 1 : -1);
				break;
			case "number_desc":
				sorted = [...projects].sort((a, b) => (a.project_number < b.project_number) ? 1 : -1);
				break;
			case "status_urgently":
				sorted = sortByStatus(projects, [ 'URGENTLY', 'AT_WORK', 'UNDER_CONSIGNMENT', 'COMPLETED' ]);
				break;
			case "status_on_work":
				sorted = sortByStatus(projects, [ 'AT_WORK', 'URGENTLY', 'UNDER_CONSIGNMENT', 'COMPLETED' ]);
				break;
			case "status_completed":
				sorted = sortByStatus(projects, [ 'COMPLETED', 'URGENTLY', 'AT_WORK', 'UNDER_CONSIGNMENT' ]);
				break;
		}

		setProjects(sorted);
	}

	const sortByStatus = (data, arrForSort) => {
		return [...data].sort((a, b) => {
			let valueA, valueB;
			arrForSort.map((el, index) => {
				if(a.status ===el)
					valueA = index;
				if(b.status ===el)
					valueB = index;
			})
			if(valueA > valueB)
				return 1;
			else if(valueA < valueB)
				return -1;
			else
				return 0;
		});
	}

	return (
		<>
			<div className="container-fluid pt-4 flex-grow-1">
				<div className="group_block h-100 d-flex flex-column">
					<div className="card mb-4 rounded flex-grow-1 d-flex flex-column">
						<div className="card-header">
							<div className="row mt-2 mb-2">
								<div className="col align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1 text-left">Задачи</h4>
									<div className="flex-shrink-0">
										<div className="search_wrapper d-flex align-items-center">
											<SearchBlock />
											<div onClick={handleShowAddModal} className="add_new_project ms-2">
												<PlusSquareFill />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-2 mb-2">
								<div className="col d-flex align-items-center mb-3 mt-2 mb-md-0 mt-md-0">
									<div onClick={handleShowFilterModal} className='filter-button'>
										<Funnel fontSize={30} title='Фильтр' />
										Фильтр
									</div>
								</div>

								<div className="col col-12 col-md-5 col-lg-3">
									<div className="ordering-block d-flex align-items-center">
										<SortDown fontSize={30} className='me-2' />
										<select className="form-select" onChange={sort}>
											<option value="number_desc">Номер по убыванию</option>
											<option value="number_asc">Номер по возрастанию</option>
											<option value="status_urgently">Статус "Срочно"</option>
											<option value="status_on_work">Статус "В работе"</option>
											<option value="status_completed">Статус "Завершено"</option>
										</select>
									</div>
								</div>
							</div>
						</div>
							
						<div className="card-body flex-grow-1">
							{ (isLoaded) ? 
								<>
									<div className="table-head row m-0 border-bottom d-none d-lg-flex">
										<div className="col col-1 border-1 border-light-subtle border-end p-2 ps-3 pe-3 text-start" title="Номер заказа">
											#
										</div>
										<div className="col col-3 border-1 border-light-subtle border-end p-3 text-start" title="Название">
											<List/>&nbsp;&nbsp;Название
										</div>
										<div className="col col-3 border-1 border-light-subtle border-end p-3 text-start" title="Заказчик">
											<PersonGear />&nbsp;&nbsp;Заказчик
										</div>
										<div className="col col-3 border-1 border-light-subtle border-end p-3 text-start align-middle" title="Дата сдачи">
											<Calendar2Event />&nbsp;&nbsp;Дата сдачи
										</div>
										<div className="col col-1 border-1 border-light-subtle border-end p-3 text-start" title="Сотрудник">
											Сотрудник
										</div>
										<div className="col col-1 p-3" title="Выполнено">
											Выполнено
										</div>
									</div>
									<Accordion defaultActiveKey="0" alwaysOpen style={{border: "0"}} className="project-list">

										{ projects && projects.length ?
											
											<>
												{ projects.map((element, index) => {
													return <div className={"border border-light-subtle border-top-0 border-start-0 border-end-0 " + ((index % 2 === 0) && "bg-light")} key={element.id}>
														<div className="accordion-head">
															<MainPageAccordionCustonToggle eventKey={element.id}>
																<div className="row m-0 outer-row">
																	<div className="col-number col col-12 col-md-1 border-1 border-light-subtle border-end" title={element.orderNumber}>
																		<div className="row">
																			<div className="col d-flex" data-label="№ проекта">
																				<span className="text">{element.project_number || '-'}</span>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col d-flex" data-label="№ у заказчика">
																				<span className="text">{element.customer_project_number || '-'}</span>
																			</div>
																		</div>
																	</div>
																	<div className="col col-12 col-md-3 border-1 border-light-subtle border-end" title={element.name} data-label="Название">
																		<span className="text">{element.name}</span>
																	</div>
																	<div className="col col-12 col-md-3 border-1 border-light-subtle border-end" title={baseStore.customers.find(e => e.id == element.customer)?.name} data-label="Заказчик">
																		<span className="text">{baseStore.customers.find(e => e.id == element.customer)?.name || '-'}</span>
																	</div>
																	<div className="col col-12 col-md-3 border-1 border-light-subtle border-end" title={element.contractShipmentDate} data-label="Дата">
																		<span className="text text-nowrap">
																			с {element.start_date} <br/>
																			по {element.end_date}
																		</span>
																	</div>
																	<div className="col col-12 col-md-1 border-1 border-light-subtle border-end" title={baseStore.users.find(e => e.id == element.user)?.first_name} data-label="Сотрудник">
																		<span className="text text-nowrap">{baseStore.users.find(e => e.id == element.user)?.first_name || '-'}</span>
																	</div>
																	<div className="col col-12 col-md-1 p-3 d-flex flex-column align-items-center" title={element.orderNumber} data-label="Выполнено">
																		<CustomProgressBar e={element} />

																		<span className="status">
																			{statusOptions.find(e => e.value === element.status)?.name}
																		</span>
																	</div>
																	<div className="col col-12 d-md-none justify-content-center pb-2">
																		<ChevronCompactDown />
																	</div>
																</div>
															</MainPageAccordionCustonToggle>
														</div>
														<Accordion.Collapse eventKey={element.id} >
															<div className={"border border-start-0 border-end-0 border-bottom-0 " + (index % 2 === 0 && "bg-light")}>
																<div className="row m-0 text-start">
																	<div className="col">
																		<div className="row">
																			<div className="col p-3">
																				<div className="row">
																					<div className="col col-12 col-sm-8">
																						3д модель:
																					</div>
																					<div className="col col-12 col-sm-4">
																						<div className="form-switch">
																							<input className="form-check-input" type="checkbox" checked={element.model_3d_done} disabled/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col p-3">
																				<div className="row">
																					<div className="col col-12 col-sm-8">
																						2д модель:
																					</div>
																					<div className="col col-12 col-sm-4">
																						<div className="form-switch">
																							<input className="form-check-input" type="checkbox" checked={element.model_2d_done} disabled/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col">
																		<div className="row">
																			<div className="col p-3">
																				<div className="row">
																					<div className="col col-12 col-sm-8">
																						Раскрой:
																					</div>
																					<div className="col col-12 col-sm-4">
																						<div className="form-switch">
																							<input className="form-check-input" type="checkbox" checked={element.sketch_done} disabled/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col p-3">
																				<div className="row">
																					<div className="col col-12 col-sm-8">
																						Отправлено:
																					</div>
																					<div className="col col-12 col-sm-4">
																						<div className="form-switch">
																							<input className="form-check-input" type="checkbox" checked={element.send_done} disabled/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col col-2 p-3 d-flex align-items-end justify-content-end">
																		<Link className='btn btn-dark' to={'/projects/' + element.id + '/'}>
																			Перейти
																		</Link>
																	</div>
																</div>
															</div>
														</Accordion.Collapse>
													</div>
												})}
											</>
										: <></> }
									</Accordion>
								</> : <Loader /> }
						</div>
					</div>

					{ nextPageUrl && 
						<div className="row d-flex justify-center">
							<div>
								<Button variant="outline-primary" onClick={loadMore}>
									Загрузить еще
									{ showLoadMoreLoader && <>&nbsp;<div className="loader-20"></div></>}
								</Button>
							</div> 
						</div> }
				</div>
			</div>

			{ isLoaded && 
				<AddProjectModal show={showAddProjectModal} 
								 onHide={handleCloseAddModal}
								 appendNewProject={appendNewProject} />
			}
			{ isLoaded && 
				<FilterModal    show={showFilterModal} 
								onHide={handleCloseFilterModal}
								elements={projects}
								setElements={setProjects}
								setNextPageUrl={setNextPageUrl}
								/>
			}
		</>
	)
}

export default Homepage;