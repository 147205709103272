import { useEffect } from 'react';
import { useState } from 'react';
import { observer } from "mobx-react-lite";
import {ArrowDown} from 'react-bootstrap-icons';
import $api from '../../http/api.config';

const AccordionFiles = observer((
        { files, showFileModal, index, editingRights = false }
    ) => {
    const i = index ? index : 0;

    const [elements, setElements] = useState(files);

    useEffect(() => {
        setElements(files ? files : []);
    }, [files]);

    const getFile = (id, index) => {
        elements[index].loading = true;
        setElements([...elements]);

        $api.get("/files/download/" + id, {
            responseType: 'blob'
        }).then(response => {
            // console.log(response);
            const filename = getFileName(response.headers);
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);

            elements[index].loading = false;
            setElements([...elements]);
            console.log(elements[index])
        }).catch(err => {
            // console.log(err);
            elements[index].loading = false;
            setElements([...elements]);
            console.log(elements[index])
        });

        setElements([...elements]);
    }

    const getFileName = (headers) => {
        let headerLine = headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        return filename;
    }

    const handleShowFileModal = () => {
        showFileModal(true);
    }

    return (
        <>
            <div className="accordion">
                <section>
                    <input type="checkbox" className="accordion__checkbox" id={"accordion-heading-" + i} />
                    <label className="accordion__heading" htmlFor={"accordion-heading-" + i}>
                        <p>
                            Файлы
                            <ArrowDown />
                        </p>
                    </label>
                    <div className="accordion__content">
                        <div className="files">
                            <ul>
                                { (elements && elements.length > 0) ?
                                    <>{elements.map(function(el, index) {
                                        return <li key={index}>
                                            <div onClick={(e) => getFile(el.id, index)}>
                                                <span className='file-name'> {el.name} </span>
                                                <span className="file-size">
                                                    {el.size} KB
                                                </span>
                                                { el.loading && <div className="loader-20"></div> }
                                            </div>
                                        </li>
                                    })}</>
                                    :
                                    <li>Нет загруженных файлов</li>
                                }
                            </ul>
                            { editingRights && <div onClick={() => handleShowFileModal()} className="btn btn-dark">
                                Добавить файлы
                            </div>}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
});

export default AccordionFiles;