import React from 'react';
import { CaretDownFill } from "react-bootstrap-icons";

const CustomToggleDropdown = React.forwardRef(({ children, onClick }, ref) => (
    <div
        className="menu-dropdown"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
      {children}
      <CaretDownFill />
    </div>
));

export default CustomToggleDropdown;