import { AxiosResponse } from "axios";
import $api, {auth} from "../http/api.config";
import { AuthResponse } from "../models/response/AuthResponse";

export default class AuthService {
    static async login(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/auth/jwt/create/', {username, password});
    }

    static async refresh() {
        return await $api.post<AuthResponse>('/auth/jwt/refresh/', {'refresh': `${localStorage.getItem('refreshToken')}`});
    }
}