import { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/hooks";
import useAlert from "../../util/Alert";
import $api from "../../http/api.config";

const AreYouSureModal = observer(({ show, onHide, onSureFunc }) => {
	const baseStore = useStore("baseStore");
	const [alert, setAlert] = useAlert({
		success: 'Проект успешно удален', 
		error: 'Возникла ошибка'
	});

	const handleCloseButton = () => {
		onHide();
	}

	const handleIfSureButton = async () => {
		let response = await onSureFunc();
		console.log(response);
		setAlert(response);
		console.log(alert);
	}

	return (
		<Modal  show={show}
				onHide={handleCloseButton}>
			<Modal.Header closeButton>
				<Modal.Title>Удалить</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="mb-0">
					Вы уверены что хотите удалить проект?
				</p>

				{ alert && 
					<div className="text-danger mt-3 mb-0">
						{alert}
					</div>
				}
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<div className="buttons">
					<Button variant="secondary" onClick={handleCloseButton}>
						Отмена
					</Button>
					<Button onClick={() => handleIfSureButton()} variant="primary" className='ms-2'>
						Удалить
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
});

export default AreYouSureModal;