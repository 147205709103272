import { useEffect } from 'react';
import { useState } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Loader from '../../loader/Loader';
import $api from '../../../http/api.config';
import { 
	PencilSquare,
	Trash 
} from "react-bootstrap-icons";
import { useStore } from '../../../store/hooks';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import { registerLocale } from "react-datepicker";
import useAlert from './../../../util/Alert';
import AccordionFiles from './../../parts/AccordionFiles';
import SubtasksBlock from '../../parts/SubtasksBlock';
import { observer } from 'mobx-react-lite';
import {paymentOptions, statusOptions, approvalStatusOptions} from "../../../util/choices/ProjectOptions";
import AddFileModal from "../../modals/AddFileModal";
import AreYouSureModal from "../../modals/AreYouSureModal";

const ProjectDetail = () => {
	const baseStore = useStore("baseStore");
	registerLocale("ru", ru);

	const navigate = useNavigate();
	const params = useParams();
	const requestUrl = '/';
	const [element, setElement] = useState(null);
	const [files, setFiles] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [alert, setAlert] = useAlert();
	const [showFileModal, setShowFileModal] = useState(false);
	const [showOtherLinksModal, setShowOtherLinksModal] = useState(false);
	const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);

	const editingRights = true;

	const elementId = params.id;


	useEffect(() => {
		$api.get("/api/v1/projects/" + elementId + "/").then(response => {
			console.log(response);
			setElement(response.data);
			// setFiles(response.data.files);
			setIsLoaded(true);
		});
	}, []);

	const updateElement = () => {
		$api.put(`/api/v1/projects/${element.id}/`, element)
			.then(response => {
				console.log(response);
				setAlert(response);
			})
			.catch(err => {
				console.log(err);
				setAlert(err.response);
			});
	}

	const deleteElement = () => {
		$api.delete(`/api/v1/projects/${element.id}/`)
			.then(response => {
				console.log(response);
				navigate("/");
			})
			.catch(err => {
				console.log(err);
				setAlert(err.response);
			});
	}

	const propChanged = (e, field) => {
		let el = element;
		const fieldType = e.target.type;

		if (fieldType === 'checkbox') {
			element[field] = e.target.checked;
		} else if(fieldType === 'select-one') {
			console.log(e.target.options[e.target.selectedIndex].value)
			element[field] = e.target.options[e.target.selectedIndex].value;
		} else {
			element[field] = e.target.value;
		}
		setElement({...element});
	}
	const dateChanged = (e, field) => {
		element[field] = e.toISOString().split('T')[0];
		setElement({...element});
	}

	const handleEditButton = () => setDisabled(!disabled);
	const handleCloseFileModal = () => setShowFileModal(false);

	const handleShowAreYouSureModal = (id) => {
        setShowAreYouSureModal(true);
    }
    const handleCloseAreYouSureModal = () => {
        setShowAreYouSureModal(false);
    }

	const handleShowOtherPagesModal = () => setShowOtherLinksModal(true);
	const handleCloseOtherPagesModal = () => setShowOtherLinksModal(false);

	return (
		<>
			{isLoaded ?
				<div className="detail-page">
					<div className="container-fluid text-start">
						<div className="bg-white rounded">
							<div className="row">
								<div className="col text-center">
									<h2>Проект</h2>
								</div>
							</div>
							{editingRights && <div className="row">
								<div className="col d-flex justify-content-end">
									<div onClick={handleEditButton} className="manipulate-btn">
										<PencilSquare />
									</div>
									<div onClick={handleShowAreYouSureModal} className="manipulate-btn ms-3">
										<Trash />
									</div>
								</div>
							</div>}
							<div className="content">
								<form method='POST' id='project-form'>
									<div className="row">
										<div className="col">
											<p className='label'>Номер заказа:</p>
										</div>
										<div className="col">
											<span className='font-weight-bold'>
												<input 	onChange={(e) => propChanged(e, "project_number")} 
														name="project_number" 
														className="form-control" 
														type="text" 
														defaultValue={element.project_number} disabled={disabled} />
											</span>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Номер заказа у заказчика:</span>
										</div>
										<div className="col">
											<span>
												<input onChange={(e) => propChanged(e, "customer_project_number")}
														name="customer_project_number"
														className="form-control" type="text" 
														defaultValue={element.customer_project_number} 
														disabled={disabled} />
											</span>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Наименование изделия:</span>
										</div>
										<div className="col">
											<span>
												<input onChange={(e) => propChanged(e, "name")} 
														name="name"
														className="form-control" 
														type="text" 
														defaultValue={element.name} 
														disabled={disabled} />
											</span>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Выполняет:</span>
										</div>
										<div className="col">
											<select onChange={(e) => propChanged(e, "user")} 
													name="user"
													className="form-select" 
													defaultValue={element.user} 
													disabled={disabled}>
												<option value="null">Не назначен</option>
												{ baseStore.users && baseStore.users.map((item, key) => {
													return <option key={key} value={item.id}>{item.first_name}</option>
												}) }
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Заказчик:</span>
										</div>
										<div className="col">
											<select onChange={(e) => propChanged(e, "customer")} 
													name="customer"
													className="form-select" 
													defaultValue={element.customer}
													disabled={disabled}>
												<option value="null">Не назначен</option>
												{ baseStore.customers && baseStore.customers.map((item, key) => {
													return <option key={key} value={item.id}>{item.name}</option>
												}) }
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>ДАТА НАЧАЛА РАЗРАБОТКИ:</span>
										</div>
										<div className="col">
											<DatePicker 
												name="start_date"
												className='form-control'
												locale="ru" 
												dateFormat="dd.MM.yyyy"
												selected={element.start_date && new Date(element.start_date)} 
												onChange={(e) => dateChanged(e, "start_date")}
												disabled={disabled} />
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>ДАТА ОКОНЧАНИЯ РАЗРАБОТКИ:</span>
										</div>
										<div className="col">
											<DatePicker 
												name="end_date"
												className='form-control'
												locale="ru" 
												dateFormat="dd.MM.yyyy"
												selected={element.end_date && new Date(element.end_date)} 
												onChange={(e) => dateChanged(e, "end_date")}
												disabled={disabled} />
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>3д модель:</p>
										</div>
										<div className="col">
											<div className="form-switch">
												<input onChange={(e) => propChanged(e, "model_3d_done")} 
														name="model_3d_done"
														className="form-check-input" 
														type="checkbox" 
														defaultChecked={element.model_3d_done} 
														disabled={disabled}/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>2д модель:</p>
										</div>
										<div className="col">
											<div className="form-switch">
												<input onChange={(e) => propChanged(e, "model_2d_done")} 
														name="model_2d_done"
														className="form-check-input" 
														type="checkbox" 
														defaultChecked={element.model_2d_done} 
														disabled={disabled}/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>Раскрой:</p>
										</div>
										<div className="col">
											<div className="form-switch">
												<input onChange={(e) => propChanged(e, "sketch_done")} 
														name="sketch_done"
														className="form-check-input" 
														type="checkbox" 
														defaultChecked={element.sketch_done} 
														disabled={disabled}/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>Отправлено:</p>
										</div>
										<div className="col">
											<div className="form-switch">
												<input onChange={(e) => propChanged(e, "send_done")} 
														name="send_done"
														className="form-check-input" 
														type="checkbox" 
														defaultChecked={element.send_done} 
														disabled={disabled}/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<span className='label'>Оплата:</span>
										</div>
										<div className="col">
											<select onChange={(e) => propChanged(e, "payment")} 
													name="payment"
													className="form-control" 
													value={paymentOptions.filter((e) => e.value == element.payment)[0]?.value} 
													disabled={disabled}>
												{ paymentOptions && paymentOptions.map((item, key) => {
													return <option key={key} value={item.value}>{item.name}</option>
												}) }
											</select>
										</div>
									</div>
									{/* <div className="row">
										<div className="col">
											<span className='label'>Статус:</span>
										</div>
										<div className="col">
											<select onChange={(e) => propChanged(e, "status")} 
													name="status"
													className="form-control" 
													value={statusOptions.filter((e) => e.value == element.status)[0]?.value} 
													disabled={disabled}>
												{ statusOptions && statusOptions.map((item, key) => {
													return <option key={key} value={item.value}>{item.name}</option>
												}) }
											</select>
										</div>
									</div> */}
									<div className="row">
										<div className="col">
											<span className='label'>Согласован:</span>
										</div>
										<div className="col">
											<select onChange={(e) => propChanged(e, "approval_status")} 
													name="approval_status"
													className="form-control" 
													value={approvalStatusOptions.filter((e) => e.value == element.approval_status)[0]?.value} 
													disabled={disabled}>
												{ approvalStatusOptions && approvalStatusOptions.map((item, key) => {
													return <option key={key} value={item.value}>{item.name}</option>
												}) }
											</select>
										</div>
									</div>
									
									{alert && <div className="row">
										<div className="col">
											{alert}
										</div>
									</div>}

									{!disabled &&
										<div className='row mt-2'>
											<div className="col d-flex justify-content-end">
												<Button onClick={() => updateElement()} className='button-outline' variant='outline-dark'>
													Сохранить изменения
												</Button>
											</div>
										</div>
									}
								</form>

								<br />
								{/* <AccordionFiles files={files}
									showFileModal={setShowFileModal}
									editingRights={editingRights} /> */}

								<br />
								<br />

								<SubtasksBlock title="Подзадачи"
									equipmentType="MANAGERS"
									editingRights={editingRights} />

								<br />
								<br />
							</div>
						</div>
					</div>
				</div>
				: <Loader />
			}

			<AreYouSureModal
                show={showAreYouSureModal}
                onHide={handleCloseAreYouSureModal}
                onSureFunc={deleteElement}
                 />
			

			{/* <AddFileModal 	projectId={elementId}
							show={showFileModal} 
							onHide={handleCloseFileModal} /> */}

		</>
	)
}

export default observer(ProjectDetail);