import axios, { AxiosRequestConfig } from 'axios';
import { AuthResponse } from '../models/response/AuthResponse';

export const API_URL = process.env.REACT_APP_API_URL;

export const auth = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

const $api = axios.create({
    // к запросу будет прикрепляться cookies
    withCredentials: true,
    baseURL: API_URL,
});

// создаем перехватчик запросов
// который к каждому запросу добавляет accessToken из localStorage
$api.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers = config.headers ?? {}
        
    if(localStorage.getItem('accessToken'))
        config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    return config;
});

// создаем перехватчик ответов
// который в случае невалидного accessToken попытается его обновить
// и переотправить запрос с обновленным accessToken
$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = {...error.config};
    originalRequest._isRetry = true;
    console.log(error)
    if(error.response.status === 401 && error.config && !error.config._isRetry) {
        try {
            if(!localStorage.getItem('refreshToken')) throw new Error("Нет refresh токена");
            const response = await auth.post<AuthResponse | any>(`${API_URL}/auth/jwt/refresh`, {'refresh': `${localStorage.getItem('refreshToken')}`});
            localStorage.setItem('accessToken', response.data.access);
            localStorage.setItem('refreshToken', response.data.refresh);
            
            return $api.request(originalRequest);
        } catch(e) {
            console.log('AUTH ERROR');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }
    }
    throw error;
});

export const $ax = (contenttype: string = "application/x-www-form-urlencoded") => {
    const axiosinstance = axios.create({
        // к запросу будет прикрепляться cookies
        withCredentials: true,
        baseURL: API_URL,
        headers: {
            "Content-Type": contenttype,
        },
    });
    
    // создаем перехватчик запросов
    // который к каждому запросу добавляет accessToken из localStorage
    axiosinstance.interceptors.request.use((config: AxiosRequestConfig) => {
        console.log(config);
        config.headers = config.headers ?? {}
         
        if(localStorage.getItem('accessToken'))
            config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
        return config;
    });
    
    // создаем перехватчик ответов
    // который в случае невалидного accessToken попытается его обновить
    // и переотправить запрос с обновленным accessToken
    axiosinstance.interceptors.response.use((config) => {
        return config;
    }, async (error) => {
        const originalRequest = {...error.config};
        originalRequest._isRetry = true;
        console.log(error)
        if(error.response.status === 401 && error.config && !error.config._isRetry) {
            try {
                if(!localStorage.getItem('refreshToken')) throw new Error("Нет refresh токена");
                const response = await auth.post<AuthResponse | any>(`${API_URL}/auth/jwt/refresh`, {'refresh': `${localStorage.getItem('refreshToken')}`});
                localStorage.setItem('accessToken', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
                
                return axiosinstance.request(originalRequest);
            } catch(e) {
                console.log('AUTH ERROR');
                localStorage.removeItem('accessToken');
                // localStorage.removeItem('refreshToken');
            }
        }
        throw error;
    });

    return axiosinstance;
}

export default $api;