import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { observer } from "mobx-react-lite";
// import { useErrorStatus } from '../contexts/ErrorStatusContext';

import {
	PencilSquare,
	PlusSquareFill,
	Check as CheckIcon,
	X as CloseIcon,
	XSquareFill
} from "react-bootstrap-icons";
import useAlert from '../../util/Alert';
import { useStore } from '../../store/hooks';
import $api from '../../http/api.config';

const SubtasksBlock = observer((
	{
		title,
		editingRights = false
	}
) => {
	const baseStore = useStore("baseStore");
	const params = useParams();
	const [disabled, setDisabled] = useState(true);
	const [elements, setElements] = useState([]);
	const [alert, setAlert] = useAlert();
	const projectId = params.id;

	useEffect(() => {
		$api.get(`/api/v1/projects/${projectId}/subtasks/`).then(response => {
			console.log(response);
			setElements(response.data);
		}).catch(err => {
			// console.log(err);
		});
	}, []);

	const addElement = () => {
        let data = {
            "done": false,
            "message": "",
            "comment": "",
            "to_whom": null,
			"project": projectId
        };
        $api.post(`/api/v1/projects/${projectId}/subtasks/`, data)
            .then(response => {
                console.log(response);
				setElements([...elements, response.data]);
            })
            .catch(err => {
                console.log(err);
                setAlert(err.response);
            });
	}

	const propChanged = (e, index, type) => {
		const fieldType = e.target.type;

		if(fieldType) {
			if (fieldType === 'checkbox') {
				elements[index][type] = e.target.checked;
			} else if(fieldType === 'select-one') {
				elements[index][type] = Number(e.target.options[e.target.selectedIndex].value);
			}			
		} else {
			elements[index][type] = e.currentTarget.textContent;
		}
		setElements(elements);
	}

	const update = () => {
		$api.put(`/api/v1/projects/${projectId}/subtasks/`, elements).then(response => {
			console.log(response);
			setElements(response.data);
			setAlert(response)
		}).catch(err => {
			console.log(err);
			setAlert(err.response)
		});
	}

	const deleteTask = (index) => {
		$api.delete(`/api/v1/projects/${projectId}/subtasks/${elements[index].id}/`).then(response => {
			console.log(response);
			setElements([...elements.filter(item => item.id !== elements[index]?.id)]);
			setAlert(response)
		}).catch(err => {
			console.log(err);
			setAlert(err.response)
		});
	}

	const handleEditButton = () => setDisabled(!disabled);

	return (
		<>
			{editingRights && <div className="row">
				<div className="col d-flex justify-content-end">
					<div onClick={handleEditButton} className="manipulate-btn">
						<PencilSquare />
					</div>
				</div>
			</div>}

			<div className="todo-block">
				<div className="row">
					<div className="col text-center">
						<h2>{title}</h2>
					</div>
				</div>
				<div className="content container-fluid">
					{(elements && elements.length) ?
						<div className='task'>
							{ elements.map((element, index) => {
								return <div className='row' key={index}>
										<div className="col col-auto">
											<input onChange={e => propChanged(e, index, 'done')} className="form-check-input" type="checkbox" defaultChecked={element.done} disabled={disabled} data-label="Статус" />
										</div>
										<div onInput={e => propChanged(e, index, 'message')} className="col" contentEditable={!disabled} data-label="Сообщение">
											{element.message}
										</div>
										<div onInput={e => propChanged(e, index, 'comment')} className="col" contentEditable={!disabled} data-label="Ответ">
											{element.comment}
										</div>
										<div className="col col-auto" data-label="Выполняет">
											<select onChange={e => propChanged(e, index, 'to_whom')} className="form-select border border-light" defaultValue={baseStore.users.filter(e => e.id == element.to_whom)[0]?.id} disabled={disabled}>
												<option value="null">Не назначен</option>
												{ baseStore.users && baseStore.users.map((item, key) => {
													return <option key={key} value={item.id}>{item.first_name}</option>
												}) }
											</select>
										</div>
										<div className="col col-auto">
											<div onClick={() => deleteTask(index)} className="delete-task-button" disabled={disabled}>
												<XSquareFill />
											</div>
										</div>
									</div>  })
							}
						</div>
						:
						<div className="mb-3 text-center">Нет подзадач</div>
					}
				</div>

				{!disabled &&
					<div className="row">
						<div className="col col-12 d-flex justify-content-center">
							<div onClick={() => addElement()} className="add-subtask-btn">
								<PlusSquareFill />
							</div>
						</div>
					</div>}
			</div>
			{alert && <div className="row">
				<div className="col">
					{alert}
				</div>
			</div>}

			{!disabled &&
				<div className='row mt-3'>
					<div className="col d-flex justify-content-end">
						<Button onClick={update} className='button-outline' variant='outline-dark'>
							Сохранить изменения
						</Button>
					</div>
				</div>
			}
		</>
	)
});

export default SubtasksBlock;