const paymentOptions = [
	{ 
		value: "NOT_PAID",
		name: "Не оплачено"
	},
	{ 
		value: "PARTIALLY_PAID",
		name: "Частично оплачен"
	},
	{ 
		value: "PAID",
		name: "Оплачено"
	}
];

const statusOptions = [
	{ 
		value: "AT_WORK",
		name: "В работе"
	},
	{ 
		value: "COMPLETED",
		name: "Завершено"
	},
	{ 
		value: "URGENTLY",
		name: "Срочно"
	},
	{ 
		value: "UNDER_CONSIGNMENT",
		name: "На согласовании"
	},
	{ 
		value: "FROZEN",
		name: "Заморожен"
	}
];

const approvalStatusOptions = [
	{ 
		value: "NOT_APPROVED",
		name: "Не согласован"
	},
	{ 
		value: "SENT_FOR_APPROVAL",
		name: "Отправлен на согласование"
	},
	{ 
		value: "APPROVED", 
		name: "Согласован"
	},
	{ 
		value: "FROZEN",
		name: "Заморожен"
	}
];

export {paymentOptions, statusOptions, approvalStatusOptions};