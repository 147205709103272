import logo from '../../static/images/Gal’amTec.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Dropdown } from 'react-bootstrap';
import CustomToggleDropdown from './../parts/CustomToggleDropdown';
import { useStore } from './../../store/hooks';
import { useEffect, useState } from 'react';

const Header = observer(() => {
    const authStore = useStore("authStore");
    const [user, setUser] = useState(authStore.user);
    const navigate = useNavigate();

    useEffect(() => {
        setUser(authStore.user);
    }, []);

    const logOut = () => {
        authStore.logout();
    }

    return (
        <Navbar bg="light" expand="md" className='navbar navbar-expand-lg navbar-dark bg-dark p-3 text-white'>
            <Container fluid>
                <Navbar.Brand>
                    <Link to='/' className='link'>
                        <img src={logo} className='logo' alt="" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav>
                        <Dropdown align="end"
                                  variant="Default">
                            <Dropdown.Toggle id="dropdown-basic" as={CustomToggleDropdown} >
                                <span className='d-inline-block p-2'>
                                    {authStore.username}
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={logOut}>
                                    Выйти из системы
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
});

export default Header;